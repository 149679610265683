<template>
  <div>
    <div class="vx-row mb-12">

      <vs-button v-if="this.$route.fullPath == '/billing'" v-on:click="submitGenerateInvoice('')" icon="done" color="primary">Generate Invoice</vs-button>
      <vs-button v-else v-on:click="submitGenerateInvoice('/one')" icon="done" color="primary">Generate One Invoice</vs-button>
    </div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow : '', detailHide]">
        <!-- 
       ADDONs
         
        -->
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="thead">
            <th width="15%">
              <vs-checkbox
                color="success"
                v-on:click="addAllValidate()"
                v-model="validateAll"
              >Generate All</vs-checkbox>
            </th>
          </template>
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.salesOrders"
              v-bind:class="[selectedData.ID==tr.ID ? 'colored' : '']"
            >
              <!-- <vs-td :data="data[indextr].id">{{data[indextr].id}}</vs-td> -->
              <vs-td>
                <vs-checkbox
                  v-if="PodCanGenerate.includes(tr.LastPod)"
                  color="success"
                  :checked="validate.includes(tr.ID)"
                  v-on:click="addValidate(tr.ID)"
                ></vs-checkbox>
                <vs-chip v-else color="danger">{{Pod[tr.LastPod]}}</vs-chip>
              </vs-td>

              <vs-td>
                Code : {{tr.Code}}
                <br />
                Notes  : {{tr.Notes}}
                <br />
                Last POD Status : {{Pod[tr.LastPod]}}
              </vs-td>
              <vs-td>
                <!-- Code : {{tr.ClientCode}}<br> -->
                Name : {{tr.CustomerName}}
                <br />
                Address : {{tr.CustomerAddress}}
                <br />
                Mobile : {{tr.CustomerMobile}}
                <br />
              </vs-td>
              <vs-td>
                Tax : {{priceFormat(tr.TaxAmount)}}
                <br />
                Discount : {{priceFormat(tr.Discount)}}
                <br />
                Total : {{priceFormat(tr.Total)}}
                <br />
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <!-- detail-->
      <transition name="detail-fade">
        <div v-if="detail" v-bind:class="[detail ? detailShow : '', detailHide]">
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              >Close</vs-button>
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
export default {
  components: {},
  props: {
    selected: Object,
    option: Object,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "delivery_status.code",
      },
      Pod: [
        "",
        "Complete",
        "Partial Complete",
        "Partial Redeliver",
        "Failed Cancel",
        "Failed Redeliver",
      ],
      PodCanGenerate: [1, 2],
      header: [
        {
          text: "Sales Order",
          value: "delivery_status.code",
          // width: '5%'
        },
        {
          text: "Client",
          sortable: false,
        },
        {
          text: "Amount",
          sortable: false,
        },
      ],
      isBilling:0,
      responseData: {},
      detailShow: "core vx-col md:w-1/2 w-full mb-base ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      selectedData: {},
      validateAll: false,
      validate: [],
    };
  },
  computed: {
    territory: {
      get: function () {
        return (
          "(" +
          this.selected.territory.code +
          ") " +
          this.selected.territory.name
        );
      },
    },
  },
  watch: {
    territory: function () {
      // this.kitting.territory = this.selected.territory
      this.reloadData(this.params);
    },
  },
  mounted() {
    if(this.$route.fullPath == '/billing'){
      this.isBilling = 1;
    }
    this.reloadData(this.params);
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  beforeMount() {
    if(this.$route.fullPath == '/billing'){
      this.isBilling = 1;
    }
  },
  methods: {
    clickShow(tr) {
      this.closeDetail();
      setTimeout(function () {}, 3000);
      // var option = this.option.territory.filter(function(territory)  {
      //       return territory.id == tr.TerritoryID;
      //     })[0]
      this.$emit("changeTab", 1, tr.promotion, false);
    },
    closeDetail() {
      this.detail = false;
      this.selectedData = {
        ID: 0,
      };
    },
    addValidate(ID) {
      console.log(this.validate.length);
      if (this.validate.includes(ID)) {
        this.validate.splice(this.validate.indexOf(ID), 1);
      } else {
        this.validate.push(ID);
      }
    },
    addAllValidate() {
      console.log(this.responseData);
      if (this.validateAll == false) {
        for (var i in this.responseData.salesOrders) {
          if (!this.validate.includes(this.responseData.salesOrders[i].ID)) {
            this.validate.push(this.responseData.salesOrders[i].ID);
          }
        }
      } else {
        this.validate = [];
      }
    },
    submitGenerateInvoice(flagUrl) {
      this.$vs.loading();

      this.$http
        .post("api/v1/invoice/generate"+flagUrl, {
          sales_order_id: this.validate,
          status: parseInt(1),
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.reloadData(this.params);
          } else {
            this.$vs.notify({
              title: "Danger",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-slash",
            });
          }
        });
      this.$vs.loading.close();
    },
    reloadData(params) {
      if(this.$route.fullPath == '/billing'){
        this.isBilling = 1;
      }
      this.params = params;
      console.log(params);
      this.$vs.loading();
      this.$http
        .get("api/v1/invoice/list/sales-order", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            is_billing: this.isBilling,
            territory_id: this.selected.territory.id,
            status: 0,
          },
        })
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.data = resp.data.salesOrders;
            this.responseData = resp.data;
            this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
    },
  },
};
</script>
 <style scoped>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
.colored {
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>