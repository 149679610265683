<template>
  <div>
    <div class="vx-row mb-12">
      <div v-bind:class="[detail ? detailShow+' vertical-divider' : '', detailHide]">
        <!-- 
       ADDONs
         
        -->

        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in responseData.invoices"
              v-bind:class="[selectedData.ID==tr.ID ? 'colored' : '','']"
              style="{border: 1px solid}"
            >
              <vs-button v-if="selectedData.ID==tr.ID"
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              >Close</vs-button>

              <!-- <vs-td :data="data[indextr].ID">{{data[indextr].ID}}</vs-td> -->
              <vs-td>
                <template>
                  <vx-tooltip text="Print Invoice">
                    <vs-button
                      color="success"
                      type="line"
                      icon-pack="feather"
                      @click="printInvoice(tr)"
                      icon="icon-printer"
                    />
                  </vx-tooltip>
                  <vx-tooltip text="Payment Invoice">
                    <vs-button
                      color="blue"
                      type="line"
                      icon-pack="feather"
                      @click="paymentInvoice(tr)"
                      icon="icon-file-text"
                    />
                  </vx-tooltip>
                </template>
              </vs-td>
              <vs-td>
                Code : {{tr.Code}}
                <br />
                Faktur : {{tr.FakturNumber}}
                <br />
                Date : {{ dateFormat(tr.Date) }}
                <br />
                Due Date : {{dateFormat(tr.DueDate)}}
                <br />
                Posting Date : {{dateFormat(tr.PostingDate)}}
                <br />
                Sales Order Code : {{responseData.salesOrder[indextr].Code}}
                <br />
                Invoice Reference Code : {{ tr.InvoiceReferenceCode  }}
                <br />
                Note : {{tr.Notes}}
                <br />
                Created At : {{dateFormat(tr.CreatedAt)}}
              </vs-td>
              <vs-td>
                Code : {{tr.ClientCode}}
                <br />
                Name : {{tr.ClientName}}
                <br />
              </vs-td>
              <vs-td>
                Subtotal : {{priceFormat(tr.Subtotal)}}
                <br />
                Discount : {{priceFormat(tr.Discount)}}
                <br />
                Subtotal Charge : {{priceFormat(tr.SubtotalCharge)}}
                <br />
                Other Cost : {{priceFormat(tr.OtherCost)}}
                <br />
                Tax : {{priceFormat(tr.TaxAmount)}}
                <br />
                Total : {{priceFormat(tr.Total)}}
                <br />
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <!-- detail-->
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '', detailHide]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              >Close</vs-button>
            </vs-col>

            <form-payment :invoice="selectedData"></form-payment>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>


<script>
import form from "./form.vue";
import moment from "moment";
export default {
  components: {
    "form-payment": form
  },
  props: {
    selected: Object,
    option: Object
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "asc",
        sort: "code"
      },
      header: [
        {
          text: "Action",
          sortable: false,
          width: "5%"
        },
        {
          text: "Invoice",
          value: "code"
          // width: '5%'
        },
        {
          text: "Client",
          sortable: false
        },
        {
          text: "Amount",
          sortable: false
        }
      ],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {}
    };
  },
  computed: {
    territory: {
      get: function() {
        return (
          "(" +
          this.selected.territory.code +
          ") " +
          this.selected.territory.name
        );
      }
    }
  },
  watch: {
    territory: function() {
      // this.kitting.territory = this.selected.territory
      this.reloadData(this.params);
    }
  },
  mounted() {
    this.reloadData(this.params);

    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].ID
  },
  methods: {
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    printInvoice(invoice) {
     let name = 'invoice-consignment-sales-print'
      if(this.$route.fullPath == '/billing'){
        name = 'invoice-print'
      }
      
      let routeData = this.$router.resolve({
        name: name,
        params: { id: invoice.ID }
      });
      console.log(routeData.href);
      window.open(routeData.href, "_blank");
      // window.print()
    },
    paymentInvoice(invoice) {
      window.scrollTo(0,0);
      this.selectedData = invoice;
      this.detail = true;
    },
    closeDetail() {
      window.scrollTo(0,0);
      this.detail = false;
      this.selectedData = {
        ID: 0
      };
    },

    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();
      this.$http
        .get("api/v1/invoice/data-table", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            territory_id: this.selected.territory.ID,
            status: 4
          }
        })
        .then(resp => {
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.data = resp.data.invoices;
            this.responseData = resp.data;
            this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
      // this.$http
      // .get("/api/v1/master/price-rule", {
      //   params: {
      //     search: params.search,
      //     length: params.length,
      //     page: params.page,
      //     order: "",
      //     sort: "",
      //   }
      // })
    }
  }
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
</style>