<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="Billing">
            <!-- <div class="vx-row mb-6" style="width:50%;">
                <div class="vx-col sm:w-1/3 w-full flex items-center" style="margin-bottom: 30px;">
                <span>Territory</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                <multiselect
                    class="selectExample"
                    v-model="selected.territory"
                    :options="option.territory"
                    :multiple="false"
                    :allow-empty="false"
                    :group-select="false"
                    :max-height="100"
                    :limit="3"
                    placeholder="Type to search"
                    track-by="id"
                    @select="selectedOption"
                    label="name"
                    style="z-index: 99;"
                >
                    <template slot="singleLabel" slot-scope="props">
                    <span class="option__desc">
                        <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
                    </span>
                    </template>

                    <template slot="option" slot-scope="props">
                    <div class="option__desc">
                        <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
                    </div>
                    </template>
                </multiselect>
                </div>
            </div> -->
            <vs-tabs v-model="selected.tab">
                <vs-tab @click="colorx = 'danger'" label="Sales Order Complete" v-if="this.$route.path=='/billing' || this.$route.path=='/consignment-sales'">
                    <div class="con-tab-ejemplo">
                        <sales-order-complete :selected="selected"  @changeTab="selectedTab"></sales-order-complete>
                        
                    </div>
                </vs-tab>
                 <!-- <vs-tab @click="colorx = 'danger'" label="Invoice Open">
                    <div class="con-tab-ejemplo">
                        <list-invoice :selected="selected"  @changeTab="selectedTab"></list-invoice>
                        
                    </div>
                </vs-tab> -->
                <!-- <vs-tab @click="colorx = 'danger'" label="Form Invoice">
                    <div class="con-tab-ejemplo">
                        <form-invoice  :selected="selected"  @changeTab="selectedTab"></form-invoice>
                    </div>
                </vs-tab> -->
                <!-- <vs-tab @click="colorx = 'danger'" label="Valid">
                    <div class="con-tab-ejemplo">
                        <validation-invoice :selected="selected"  @changeTab="selectedTab"></validation-invoice>
                    </div>
                </vs-tab> -->
                <vs-tab @click="colorx = 'danger'" label="Waiting Payment">
                    <div class="con-tab-ejemplo">
                        <waiting-payment-invoice :selected="selected"  @changeTab="selectedTab"></waiting-payment-invoice>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'danger'" label="Partial Received">
                    <div class="con-tab-ejemplo">
                        <partial-payment-invoice :selected="selected"  @changeTab="selectedTab"></partial-payment-invoice>
                        <!-- <release-invoice :selected="selected"  @changeTab="selectedTab"></release-invoice> -->
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'danger'" label="Complete">
                    <div class="con-tab-ejemplo">
                        <closed :selected="selected"  @changeTab="selectedTab"></closed>
                    </div>
                </vs-tab>
                <vs-tab @click="colorx = 'danger'" label="Reversed">
                    <div class="con-tab-ejemplo">
                        <reversed :selected="selected"  @changeTab="selectedTab"></reversed>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
         
    </div>
</template>
<script>
import form from "./form/form.vue";
import list from "./list/list.vue";
import closed from "./closed/closed.vue";
import reversed from "./reversed/reversed.vue";
import validation from "./validation/validation.vue";
import waitingPayment from "./waiting-payment/waiting-payment.vue"
import partialPayment from "./partial-payment/partial-payment.vue"
import approval from "./approval/approval.vue";
import release from "./release/release.vue";
import complete from "./complete/complete.vue";
import SalesOrderComplete from "./sales_order_complete/sales_order_complete.vue";

export default {
    components:{
        'form-invoice':form,
        'list-invoice':list,
        'validation-invoice':validation,
        'approval-invoice':approval,
        'release-invoice':release,
        'complete-invoice':complete,
        'waiting-payment-invoice':waitingPayment,
        'closed':closed,
        'partial-payment-invoice':partialPayment,
        'sales-order-complete':SalesOrderComplete,
        'reversed':reversed
    },
    data: () => ({
        selected : {
            territory: {},
            tab : 0,
            itemKit : {},
            isEdit:true
        }
    }),
    watch: {
        selected:{
            territory: function() {
                console.log("selected")
            },
        }
    },
    methods:{
         selectedTab(value,invoice,isEdit = false){
            //  this.selected.territory  = option
             this.selected.invoice = invoice
             console.log(this.selected.invoice)
             this.selected.tab = value
             this.selected.isEdit = isEdit
         }
    },
    mounted(){
        // this.showOption()
    }
}
</script>
<style >
    .multiselect__tags {
        min-height: 32px;
        display: block;
        padding: 3px 40px 0 8px;
        border-radius: 5px;
        border: 1px solid #e8e8e8;
        background: #fff;
        font-size: 14px;
    }
    .multiselect__content-wrapper{
        z-index:100;
        background: #fff;
    }
</style>